import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading';
import games from './games';
import game from './game';
import gameHealth from "./gameHealth";
import scenes from './scenes';
import auth from './auth';

export default combineReducers({
    auth,
    games,
    game,
    gameHealth,
    scenes,
  loadingBar: loadingBarReducer
});
