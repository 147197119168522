import React from 'react';

import {Rnd} from 'react-rnd';
import GamePlayer from '../videoPlayer/GamePlayer';

class GameVideoPlayer extends React.Component {

    render() {
      const { isVisible } = this.props;
        return (
            <>
             <Rnd
                 style={{display: isVisible ? 'block': 'none' }}
                  className="box"
                  default={{
                    x: 10,
                    y: 10,
                    width: 850,
                    height: 400,
                  }}
                  minWidth={812}
                  minHeight={375}
                  bounds="window"
              > { isVisible &&
                  <GamePlayer />
             }
              </Rnd>
              </>

        );
    }
}

export default GameVideoPlayer
