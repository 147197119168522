import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ResourcesEditor from 'components/ResourcesEditor';
import FragmentSelector from 'components/FragmentSelector';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    title: {
        fontSize: '14px'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '12px'
    },
    panelDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    panelSummary: {
        paddingLeft: 0,
        paddingRight: 0
    }
});

class ActionsEditor extends React.Component {
    state = {
        open: false,
        expanded: null,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleOpenFragmentSelector = () => {
        this.setState({open: true});
    };

    handleCloseFragmentSelector = value => {
        if (typeof value !== 'object')
            this.props.addAction(this.props.fId, value);
        this.setState({open: false});
    };

    handleUpdateAction(action) {
        this.props.updateAction(this.props.fId, action);
    }

    render() {
        const {classes, shootId, site} = this.props;
        const {expanded} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.titleContainer}>
                    <Typography variant='title' component='h5' className={classes.title}>Actions</Typography>
                    <Button variant="outlined" size="small" onClick={this.handleOpenFragmentSelector}>
                        Add Action
                    </Button>
                </div>

                <FragmentSelector
                    open={this.state.open}
                    onClose={this.handleCloseFragmentSelector}/>

                <div>
                    {this.props.actions && this.props.actions.length > 0 && this.props.actions.map(
                        (action, index) => {
                            return (<ExpansionPanel
                                key={index} elevation={0}
                                expanded={expanded === action.id}
                                onChange={this.handleChange(action.id)}>
                                <ExpansionPanelSummary
                                    className={classes.panelSummary}
                                    expandIcon={<ExpandMoreIcon/>}>
                                    <Typography
                                        className={classes.heading}>{action.title || 'A. Fragment ' + action.fragmentId}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.panelDetails}>
                                    <ResourcesEditor
                                        shoot={shootId}
                                        site={site}
                                        fragment={action}
                                        onTitleUpdate={this.handleUpdateAction.bind(this)}/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>);
                        })}
                </div>

            </div>
        );
    }
}

ActionsEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    updateAction: PropTypes.func,
    fId: PropTypes.string
};

export default withStyles(styles, {withTheme: true})(ActionsEditor)