import React from 'react';
import {connect} from 'react-redux';
import {Link, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {
    Divider,
    Drawer,
    Icon,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Routes from "../config/routes";
import AppToolbar from "../components/AppToolbar";
import handleInitialData from "../redux/actions/shared";

const drawerWidth = 240;
const drawerLinkActiveStyle = ({
    textDecoration: 'none',
});

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        maxWidth: '100%'
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarTitle: {
        marginLeft: '8px',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        maxWidth: '100%',
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            '& $primary, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    selected: {
        backgroundColor: theme.palette.secondary.main + ' !important',
        '& $primary, & $icon': {
            color: theme.palette.common.white,
        },
    },
    primary: {},
    icon: {
        marginLeft: '6px',
    },
});

class AppDrawer extends React.Component {
    state = {
        open: false,
    };

    componentDidMount() {
        this.props.handleInitialData();
    }

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    handleDrawerToggle = () => {
        this.setState({open: !this.state.open});
    };

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }

    render() {
        const {classes, theme} = this.props;
        return (
            <div className={classes.root}>
                <AppToolbar {...this.state} handleDrawerToggle={this.handleDrawerToggle}></AppToolbar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}>
                    <div className={classes.toolbar}>
                        <Typography variant="title" className={classes.toolbarTitle}>Sex Adventure</Typography>
                        <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </div>
                    <Divider/>
                    <MenuList>
                        {Routes.map((route, key) => {
                            if (route.showOnSidebar) {
                                return (
                                    <Link to={route.path} style={drawerLinkActiveStyle} key={key}>
                                        <MenuItem className={classes.menuItem} selected={this.activeRoute(route.path)}
                                                  classes={{selected: classes.selected}}>
                                            <ListItemIcon className={classes.icon}>
                                                <Icon>{route.icon}</Icon>
                                            </ListItemIcon>
                                            <ListItemText classes={{primary: classes.primary}} primary={route.name}/>
                                        </MenuItem>
                                    </Link>
                                );
                            }
                        })}
                    </MenuList>
                    <Divider/>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    {Routes.map((route, key) => {
                        return (
                            <Route exact={route.exact} path={route.path} component={route.component} key={key}/>
                        );
                    })}
                </main>
            </div>
        );
    }
}

AppDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        handleInitialData: () => dispatch(handleInitialData())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(AppDrawer))