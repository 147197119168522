import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    Icon,
    Input,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Helpers from 'utils/helpers'
import red from "@material-ui/core/colors/red";
import FragmentSelector from 'components/FragmentSelector'
import SceneSelector from 'components/SceneSelector'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: '30px'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    title: {
        fontSize: '18px'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '12px'
    },
    panelDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    contentEditor: {
        display: 'flex',
        '& button': {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
            marginTop: '15px',
            width: '110px'
        }
    },
    btnLeft: {
        marginRight: '5px'
    },
    poster: {
        width: '90px',
        borderRadius: '8px',
        marginRight: '20px'
    },
    contentFields: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    formControl: {
        width: '100%'
    }
});

class ChoicesEditor extends React.Component {
    state = {
        open: false,
        sceneSelectorOpen: false,
        expanded: null,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleTitleChange = (choice, event) => {
        choice.title = event.target.value;
        this.props.updateChoice(choice);
    };

    handleOpenFragmentSelector = () => {
        this.setState({open: true});
    };

    handleCloseFragmentSelector = value => {
        if (typeof value !== 'object')
            this.props.addChoice(value);
        this.setState({open: false});
    };

    handleOpenSceneSelector = () => {
        this.setState({sceneSelectorOpen: true});
    };

    handleCloseSceneSelector = value => {
        if (value.fragments)
            this.props.addChoice(value.fragments[0].fragmentId, value._id);

        this.setState({sceneSelectorOpen: false});
    };

    render() {
        const {classes, shoot, removeChoice, choices, site} = this.props;
        const {expanded} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.titleContainer}>
                    <Typography variant='title' component='h3' className={classes.title}>Choices</Typography>
                    <div>
                        <Button className={classes.btnLeft} variant="outlined" size='small'
                                onClick={this.handleOpenFragmentSelector}>
                            Add New Scene
                        </Button>
                        <Button variant="outlined" size='small' onClick={this.handleOpenSceneSelector}>
                            Add Existent
                        </Button>
                    </div>

                </div>

                <FragmentSelector
                    open={this.state.open}
                    onClose={this.handleCloseFragmentSelector}/>

                <SceneSelector
                    choices={choices}
                    open={this.state.sceneSelectorOpen}
                    onClose={this.handleCloseSceneSelector}/>

                <div>
                    {choices && choices.length > 0 && choices.map(
                        (choice, index) => {
                            return (
                                <ExpansionPanel
                                    key={index}
                                    expanded={expanded === index}
                                    onChange={this.handleChange(index)}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography className={classes.heading}>
                                            {choice.title || 'Choice ' + index + 1}
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.panelDetails}>
                                        <div className={classes.contentEditor}>
                                            <div>
                                                <img className={classes.poster}
                                                     src={Helpers.getImagePath(choice.poster, shoot, null, site)}/>
                                            </div>
                                            <div className={classes.contentFields}>
                                                <FormControl className={classes.formControl}>
                                                    <Input
                                                        className={classes.input}
                                                        value={choice.title}
                                                        onChange={(e) => this.handleTitleChange(choice, e)}
                                                        placeholder='Title'
                                                        inputProps={{
                                                            'aria-label': 'Title',
                                                        }}/>
                                                </FormControl>
                                                {this.props.canDelete && (
                                                    <Button variant="outlined"
                                                            className={!this.props.canDelete ? classes.button : classes.disabled}
                                                            onClick={() => {
                                                                removeChoice(choice)
                                                            }}>
                                                        <Icon className={classes.btnIcon}>delete</Icon> Delete
                                                    </Button>
                                                )}

                                                {!this.props.canDelete && (
                                                    <div style={{color: 'red', paddingTop:'16px', fontSize:'small'}}>
                                                        *To delete this choice, please remove the scene's children first.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        })
                    }
                </div>

            </div>
        );
    }
}

ChoicesEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    addChoice: PropTypes.func,
    updateChoice: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(ChoicesEditor)
