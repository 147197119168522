import React, { Component } from 'react';
import { connect } from 'react-redux';
import {IconButton, Typography, Button, AppBar, Toolbar, FormControlLabel, Checkbox} from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import { handleUpdateGame } from '../redux/actions/games';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Helpers from "../utils/helpers";
import Settings from "../config/settings";
import FeatureHeaderStatus from "./FeatureHeaderStatus";



const styles = theme => ({
  grid: {
    '& div': {
      width: '185px'
    }
  },
  form: {
    paddingTop: '40px',
    maxWidth: '1200px',
    padding: '10px'
  },
  flex: {
    flex: 1
  },

  button: {
    paddingTop: '30px'
  },


});
const grid = 2;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: 4,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});
const scrollContainerHeight = 500;
const getListStyle = isDraggingOver => ({
  boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
  borderRadius: 4,
  marginLeft: 40,
  background: isDraggingOver ? "#d1ebff" : "#e6e6e6",
  padding: 5,
  width: 300,
  overflowX: "hidden",
  overflowY: "auto",
  maxHeight: `${scrollContainerHeight}px`
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class EditGame extends Component {
  state = {
    _id:'',
    title: '',
    description: '',
    poster: [],
    trailer: '',
    video: '',
    guide: '',
    shootId: '',
    site: '',
    fragments: '',
    status: '',
    joinImage: '',
    resolutions: [],
    storyHighlights: [],
    isFeaturedInHeader: false,
    showFeatureHeaderStatus: false,
    colorSchema: JSON.stringify({
      'primary_text': '',
      'secondary_text': '',
      'controls_navigation': '',
      'options_background1': '',
      'options_background2': '',
      'view_trailer_background': '',
      'view_movie_background': '',
      'start_game_background': ''
    })
  };

  componentDidMount() {
    const { _id, title, description, poster, trailer, video, guide, shootId, site, fragments, status, joinImage, resolutions, storyHighlights, isFeaturedInHeader, colorSchema } = this.props.game;

    this.setState({
      _id,
      title,
      description,
      poster: poster[0] !== "" ? poster : `//sm-members.bangbros.com/shoots/${site || "sexadventure"}/${shootId}/poster/${shootId}_01_2160.jpg`,
      trailer,
      video,
      guide,
      shootId,
      site: site || "sexadventure",
      fragments,
      status,
      joinImage,
      resolutions,
      storyHighlights,
      isFeaturedInHeader: isFeaturedInHeader || false,
      colorSchema
    });
  }

  handleAddResolution = resolution => {
    this.setState({
      resolutions: this.state.resolutions.concat([resolution])
    });
  };

  handleDeleteResolution = resolution => {
    const resolutions = this.state.resolutions.filter(c => c !== resolution);

    this.setState({ resolutions });
  };

  handleSaveGame = (e) => {
    e.preventDefault();
    e.target.disabled = true;

    const { dispatch, closeEditGame } = this.props;
    const { _id, title, description, poster, trailer, video, guide, resolutions, fragments, shootId, site, status, joinImage, storyHighlights, isFeaturedInHeader, colorSchema } = this.state;
    if (this.state.title && this.state.description) {
      dispatch(handleUpdateGame({  _id, title, description, poster, trailer, video, guide, resolutions, fragments, shootId, site, status, joinImage, storyHighlights, isFeaturedInHeader, colorSchema }));
      e.target.disabled = false;
      closeEditGame();
    }
  };

  handleFeatureHeaderChange = (e) => {
    if (e.target.checked) {
      this.setState({ showFeatureHeaderStatus: true });
    } else {
      this.setState({ isFeaturedInHeader: false });
    }
  }

  handleFeatureHeaderModalOption = (option) => {
    this.setState({ isFeaturedInHeader: option || false, showFeatureHeaderStatus: false });
  }

  getTemplate = (name, shootId, site) => {
    if (name === 'trailer') {
      return `https://trailers1.bangbros.com/${site}/${shootId}/trailerx/trailer_1500.mp4`;
    }
    if (name === 'video') {
      return `//cdn77-lg-members.bangbros.com/shoots/${site}/${shootId}/streaming/${shootId}-1080p.mp4`
    }
    if (name === 'poster') {
      return `//sm-members.bangbros.com/shoots/${site}/${shootId}/poster/${shootId}_01_2160.jpg`;
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });

    if (name === 'fragments' && (event.target.value < 0 || event.target.value === '')) {
      this.setState({ fragments: 0 });
    }

    if (name === 'shootId') {
      this.setState({
        ['trailer']: this.getTemplate('trailer', event.target.value, this.state.site),
        ['video']: this.getTemplate('video', event.target.value, this.state.site),
        ['poster']: this.getTemplate('poster', event.target.value, this.state.site)
      })
    }

    if (name === 'site') {
      this.setState({
        ['trailer']: this.getTemplate('trailer', this.state.shootId, event.target.value),
        ['video']: this.getTemplate('video', this.state.shootId, event.target.value),
        ['poster']: this.getTemplate('poster', this.state.shootId, event.target.value)
      })
    }


  };

  validate = () => {
    if (
      this.state.title.length > 10 &&
      this.state.description.length > 20 &&
      this.state.shootId.length > 5 &&
      this.state.fragments > 0 &&
      this.state.resolutions.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const storyHighlights = reorder(
        this.state.storyHighlights,
        result.source.index,
        result.destination.index
    );

    this.setState({
      storyHighlights
    });
  }


  render() {
    const { classes } = this.props;
    const formValid = this.validate(this.state.email, this.state.password);
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.layout}>
        <form noValidate autoComplete="off" className={classes.form}>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={8}>
              <Grid container direction="row" justify="space-between" alignItems="center" className={classes.grid}>
                <TextField
                    margin="dense"
                    id="shootId"
                    label="Shoot Id"
                    type="text"
                    value={this.state.shootId}
                    required
                    onChange={this.handleChange('shootId')}
                />
                <TextField
                    id="fragments"
                    label="How many video fragments?"
                    value={this.state.fragments}
                    onChange={this.handleChange('fragments')}
                    type="number"
                    min="0"
                    margin="dense"
                    style={{width: '205px'}}
                />
                <TextField
                    margin="dense"
                    id="site"
                    label="Site"
                    type="text"
                    value={this.state.site}
                    required
                    placeholder="sexadventure"
                    onChange={this.handleChange('site')}
                />
                <TextField
                    id="select-game-status"
                    select
                    label="Status"
                    value={this.state.status}
                    onChange={this.handleChange('status')}
                    margin="dense"
                    fullWidth={true}
                >
                  {['disable', 'active'].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <TextField
                  margin="dense"
                  id="title"
                  label="Title (Min 10 chars)"
                  type="text"
                  fullWidth
                  value={this.state.title}
                  required
                  onChange={this.handleChange('title')}
              />
              <TextField
                  margin="dense"
                  id="description"
                  label="Description  (Min 20 chars)"
                  type="text"
                  fullWidth
                  multiline
                  rowsMax="4"
                  required
                  value={this.state.description}
                  onChange={this.handleChange('description')}
              />
              <TextField
                  margin="dense"
                  id="joinImage"
                  label="Join Image"
                  placeholder="https://yourImagePath/imageName.png"
                  type="text"
                  fullWidth
                  value={this.state.joinImage}
                  onChange={this.handleChange('joinImage')}
              />
              <TextField
                  margin="dense"
                  id="guide"
                  label="Guide image url"
                  placeholder="https://yourImagePath/imageName.png"
                  type="text"
                  fullWidth
                  value={this.state.guide}
                  onChange={this.handleChange('guide')}
              />
              <TextField
                  margin="dense"
                  id="poster"
                  label="Poster image url"
                  placeholder="https://yourPosterImagePath.com/yourPosterImageName.png"
                  type="text"
                  fullWidth
                  value={this.state.poster}
                  onChange={this.handleChange('poster')}
              />
              <TextField
                  margin="dense"
                  id="trailer"
                  label="Trailer video url"
                  placeholder="https://trailers1.bangbros.com/<site-name>/<shoot-id>/trailerx/trailer_1500.mp4"
                  type="text"
                  fullWidth
                  value={this.state.trailer}
                  onChange={this.handleChange('trailer')}
              />
              <TextField
                  margin="dense"
                  id="video"
                  label="Full video url"
                  placeholder="//cdn77-lg-members.bangbros.com/shoots/<site-name>/<shoot-id>/streaming/<shoot-id>-1080p.mp4"
                  type="text"
                  fullWidth
                  value={this.state.video}
                  onChange={this.handleChange('video')}
              />

              <ChipInput
                  required
                  className={classes.chipInput}
                  helperText="Add new resolutions here (ie: 1080p, 480p, 360p)"
                  label="Resolutions availables:"
                  value={this.state.resolutions}
                  fullWidth
                  allowDuplicates={false}
                  onAdd={resolution => this.handleAddResolution(resolution)}
                  onDelete={(resolution, index) => this.handleDeleteResolution(resolution, index)}
              />

              <Typography style={{paddingTop: 30}}>Color Scheme:</Typography>
              <TextField
                  style={{border: "solid .05px",
                    boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
                    borderRadius: 4}}
                  // className={classes.chipInput}
                  margin="dense"
                  variant="outlined"
                  id="colorSchema"
                  multiline
                  rows={6}
                  fullWidth
                  value={this.state.colorSchema}
                  onChange={this.handleChange('colorSchema')}
              />
            </Grid >
            <Grid item classes={classes.fragmentList} xs={4}>
              <FormControlLabel
                  onChange={this.handleFeatureHeaderChange}
                  control={<Checkbox checked={this.state.isFeaturedInHeader} inputProps={{ 'aria-label': 'controlled' }} />}
                  label="Feature in Tour's Header"
                  style={{padding: "5px", marginLeft: "20px"}} />
              <FeatureHeaderStatus
                  isVisible={this.state.showFeatureHeaderStatus}
                  shootId={this.state.shootId}
                  site={this.state.site}
                  onClose={this.handleFeatureHeaderModalOption.bind(this)} />
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                      <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <p>Drag & drop to sort the <u>Highlights</u></p>
                        {this.state.storyHighlights.map((item, index) => (
                            <Draggable key={item} draggableId={item} index={index}>
                              {(provided, snapshot) => {
                                  const scene = this.props.scenes[item];
                                  const fragment = scene.fragments[0];

                                  const thumbnailImage = Helpers.getImagePath(Settings.thumbnail, this.state.shootId, fragment.fragmentId, this.state.site);
                                  const thumbnailName = fragment.title;
                                  return (
                                  <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                      )}
                                  >
                                    <img style={{
                                      border: "solid",
                                      borderColor: "lightgray",
                                      borderWidth: "thin",
                                      borderRadius: 4}}
                                         width="20%" src={thumbnailImage} />
                                    <span style={{paddingLeft: 40}}>{thumbnailName}</span>
                                  </div>
                              )}}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.button}>
            <Button onClick={this.handleSaveGame} disabled={!formValid} color="primary">
              Save Game
            </Button>
          </Grid>
        </form>

      </Grid>
    );
  }
}

function mapStateToProps({ games, scenes }, { id }) {
  return {
    game: games[id] || {},
    scenes: scenes,
  };
}

EditGame.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(EditGame));
