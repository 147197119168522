import {RECEIVE_GAME, REMOVE_SELECTED_GAME} from 'redux/actions/games';

export default function (state = {}, action) {
    switch (action.type) {
        case RECEIVE_GAME:
            return {
                ...state,
                ...action.game
            };
        case REMOVE_SELECTED_GAME:
            return {};
        default:
            return state;
    }
}
