import Home from "../containers/Home";
import Game from "../containers/Game";
import GameList from "../containers/GamesList";

const Routes = [
    {
        path: '/admin',
        exact: true,
        component: Home
    },
    {
        path: '/admin/games',
        exact: true,
        name: 'Games',
        icon: 'games',
        component: GameList,
        showOnSidebar: true
    },
    {
        path: '/admin/games/:gameId',
        component: Game
    }
];

export default Routes;