import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {connect} from 'react-redux';
import Helpers from 'utils/helpers';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit,
        display: 'inline-block',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.secondary.ultraLight,
            $variant: {
                color: theme.palette.common.white
            }
        },
        margin: '0 10px'
    },
    selected: {
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            $variant: {
                color: theme.palette.common.white
            }
        }
    },
    poster: {
        width: '160px',
        borderRadius: '4px'
    },
    highlighted: {
        width: '160px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.light,
        borderWidth: 'thick',
    },
    publicScene: {
        borderColor: 'red',
        borderStyle: 'dotted',
    },
    title: {
        fontSize: '0.8rem',
        fontWeight: '500',
        marginTop: '6px',
        width: '160px'
    },
    choice: {
        marginBottom: '8px',
    }
});

class SceneNode extends React.Component {
    handleClick = () => {
        this.props.onSelectScene(this.props.node.id);
    };

    render() {
        const {classes, scene, shoot, choice, node, site, showAssetStatuses, loadingAssetStatuses, sceneAssetStatuses} = this.props;
        const statusColor = showAssetStatuses && sceneAssetStatuses ? "green" : "red";

        if (!this.props.scene) {
            return '';
        }

        return (
            <div>
                {this.props.scene && (
                    <Paper
                        classes={{
                            root: classNames(
                                classes.root,
                                this.props.selected === this.props.node.id && classes.selected,
                                this.props.scene.isPublic && classes.publicScene
                            )
                        }}
                        style={{borderBottom: showAssetStatuses && !loadingAssetStatuses ? `10px solid ${statusColor}` : ""}}
                        elevation={1}
                        onClick={this.handleClick}
                    >
                        <Typography component="p"
                                    className={classes.choice}>{choice ? choice.title : ''}</Typography>
                        <img
                            className={this.props.scene.isHighlighted ? classes.highlighted : classes.poster}
                            src={Helpers.getImagePath(
                                scene.fragments && scene.fragments.length ? scene.fragments[0].thumbnail : '',
                                shoot,
                                scene.fragments && scene.fragments.length ? scene.fragments[0].fragmentId : '',
                                site
                            )}
                        />

                        <Typography className={classes.title} variant="subheading" component="h3">
                            {this.props.scene.title || '?'}
                        </Typography>

                    </Paper>
                )}
            </div>
        );
    }
}

function findChoice(scenes, choice) {
    let out;
    if (scenes) {
        for (let key in scenes) {
            const scene = scenes[key];
            const choiceFinded = scene.choices.find(_choice => _choice.id === choice);
            if (choiceFinded) {
                out = choiceFinded
            }
        }
    }
    return out;
}

function mapStateToProps({scenes, gameHealth}, ownProps) {
    const scene = scenes ? scenes[ownProps.node.id] : null;
    let sceneAssetStatuses = false;

    if (Object.keys(gameHealth).length > 0 && scene && scene?.fragments && scene.fragments.length) {
        for (const key in Object.getOwnPropertyNames(scene.fragments)) {
            const fragment = scene.fragments[key];

            if (!fragment) {
                break;
            }

            const assetStatus = gameHealth.scenesAssets[fragment.id];

            if (!assetStatus || !assetStatus.poster || !assetStatus.resource || !assetStatus.thumbnail) {
                sceneAssetStatuses = false;
                break;
            }

            sceneAssetStatuses = true;
        }
    }

    return {
        scene: scene,
        choice: findChoice(scenes, ownProps.node.choice),
        sceneAssetStatuses,
    };
}

SceneNode.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    onSelectScene: PropTypes.func
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(SceneNode));
