import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import withRoot from './withRoot';
import AppDrawer from '../containers/AppDrawer';
import handleInitialData from '../redux/actions/shared';
import {connect} from 'react-redux';
import Auth from "../utils/auth";
import SignIn from "../containers/SignIn";
import {checkAuth} from "../redux/actions/auth";

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        Auth.isAuthenticated() ?
            (<Component {...props}/>) :
            (<Redirect to={{pathname: '/login', state: {from: props.location}}}/>)
    )}/>
);

class App extends Component {

    componentDidMount() {
        this.props.checkAuth();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login" component={SignIn}/>
                    <PrivateRoute path="/admin" component={AppDrawer}/>
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        checkAuth: () => dispatch(checkAuth()),
        handleInitialData: () => dispatch(handleInitialData())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRoot(App));
