import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {clear, signIn} from "redux/actions/auth";
import connect from "react-redux/es/connect/connect";
import {Redirect} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 5,
    },
    title: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 5,
    },
    error: {
        marginBottom: theme.spacing.unit * 2,
        color: 'red'
    }
});

class SignIn extends Component {

    state = {
        username: "",
        password: ""
    };

    handleSubmit = event => {
        this.props.signIn(this.state.username, this.state.password);
        event.preventDefault();
    };

    render() {
        const {classes, auth} = this.props;
        const {username, password} = this.state;

        return (
            auth.user ?
                <Redirect to={{
                    pathname: '/admin'
                }}/>
                :
                <React.Fragment>
                    <CssBaseline/>
                    <main className={classes.layout}>
                        <Paper className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockIcon/>
                            </Avatar>
                            <Typography component="h1" variant="display1" className={classes.title}>
                                Live Selector
                            </Typography>
                            {auth.loading ? <CircularProgress className={classes.progress}/> : ''}
                            <Typography component="p" className={classes.error}>
                                {auth.error ? auth.error.message : ''}
                            </Typography>
                            <form className={classes.form} onSubmit={this.handleSubmit}>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="email">Username</InputLabel>
                                    <Input id="username"
                                           name="username"
                                           autoFocus
                                           value={username}
                                           disabled={auth.loading}
                                           onChange={(event) => this.setState({username: event.target.value})}
                                    />
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <Input
                                        name="password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={password}
                                        disabled={auth.loading}
                                        onChange={(event) => this.setState({password: event.target.value})}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={auth.loading}
                                    className={classes.submit}
                                >Sign in </Button>
                            </form>
                        </Paper>
                    </main>
                </React.Fragment>
        );
    }


}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clear: () => dispatch(clear()),
        signIn: (email, password) => dispatch(signIn(email, password))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn));