import React from "react";
import {withStyles} from '@material-ui/core/styles';
import GamePlayer from "../videoPlayer/GamePlayer";
import Icon from "@material-ui/core/Icon";

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1500",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0,0,0,0.4)",
        backdropFilter: "blur(4px)",
    },
    container: {
        border: "5px solid white",
        minWidth: "75vw",
        minHeight: "80vh",
        position: "relative",
        boxSizing: "border-box",
        boxShadow: "0 0 10px #000",
        after: {
            content: "",
            display: "block",
            paddingBottom: "100%"
        }
    },
    content: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
    circle: {
        position: "absolute",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        top: "-20px",
        right: "-20px",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 0 10px #000",
    },
    icon: {
        cursor: "pointer",
        fontWeight: "bold",
    }
});

const GamePreviewVideoPlayer = ({ isVisible, classes, onClose }) => {
    if (!isVisible) {
        return (
            <></>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.content}>
                    <GamePlayer />
                    <div className={classes.circle}>
                        <Icon className={classes.icon} color="action" onClick={onClose}>
                            close
                        </Icon>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(GamePreviewVideoPlayer)