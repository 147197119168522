import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Icon, IconButton, Paper, Typography} from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        padding: theme.spacing.unit,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        opacity: 0.3,
        transition: 'all 0.4s ease',
        '&:hover': {
            opacity: 1
        }
    },
});

class ZoomController extends React.Component {
    state = {
        initialized: false,
        pX: 0,
        pY: 0,
        width: 0,
        height: 0,
        scale: 60
    };

    componentDidUpdate(prevProps, prevState, ss) {
        const elementNode = document.getElementsByClassName('reactOrgChart')[0];
        if (elementNode) {
            const bbox = elementNode.getBoundingClientRect();
            if (!prevState.initialized && this.state.initialized === prevState.initialized) {
                this.setState({width: bbox.width, height: bbox.height, pX: bbox.x, pY: bbox.y, initialized: true});
                this.handleUpdateZoom(bbox.x, bbox.y, bbox.width, bbox.height, 60);
            }
        }
    }

    handleZoomIn() {
        const scale = this.state.scale + 10;
        if (scale + 10 <= 110) {
            this.setState({scale: scale});
            this.handleUpdateZoom(this.state.pX, this.state.pY, this.state.width, this.state.height, scale);
        }
    }

    handleZoomOut() {
        const scale = this.state.scale - 10;
        if (scale - 10 >= 0) {
            this.setState({scale: scale});
            this.handleUpdateZoom(this.state.pX, this.state.pY, this.state.width, this.state.height, scale);
        }
    }

    handleUpdateZoom(x, y, width, height, scale) {
        const elementNode = document.getElementsByClassName('reactOrgChart')[0];
        // const cx = x + (width / 2), cy = y + (height / 2);   // finding center of element
        const cx = 0, cy = 0;
        const scaleX = scale / 100, scaleY = scale / 100;    // the desired scale
        const scaleStr = scaleX + ',' + scaleY;
        const tx = -cx * (scaleX - 1);
        const ty = -cy * (scaleY - 1);
        const translateStr = tx + 'px,' + ty + 'px';

        elementNode.style.transition = 'all 0.3s ease';
        elementNode.style.transform = 'translate(' + translateStr + ') scale(' + scaleStr + ')';
        elementNode.style.transformOrigin = 'top left';
        elementNode.style.width = (width * scaleX) + 'px';
        elementNode.style.height = (height * scaleX) + 'px';

    }

    render() {
        const {classes} = this.props;

        return (
            <div className="zoom-controller">
                <Paper className={classes.root}>
                    <IconButton
                        color="secondary"
                        className={classes.button}
                        aria-label="Add an alarm"
                        onClick={this.handleZoomOut.bind(this)}>
                        <Icon>remove</Icon>
                    </IconButton>

                    <Typography variant="subheading">{this.state.scale}%</Typography>

                    <IconButton
                        color="secondary"
                        className={classes.button}
                        aria-label="Add an alarm"
                        onClick={this.handleZoomIn.bind(this)}>
                        <Icon>add</Icon>
                    </IconButton>
                </Paper>
            </div>
        );
    }
}

ZoomController.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ZoomController)