import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import {connect} from "react-redux";
import Helpers from "../utils/helpers";

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',

    },
    dialogPaper: {
        maxHeight: '50%'
    },
    dialogContent: {
        height: '100%',
        overflow: 'auto'
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
};

class SceneSelector extends React.Component {
    handleClose = () => {
        this.props.onClose('');
    };

    handleListItemClick = value => {
        this.props.onClose(value);
    };

    getSceneTitle = (sceneId) => {
        const keys = Object.keys(this.props.scenes);
        let choice;
        keys.forEach(k => {
            const scene = this.props.scenes[k];
            const _choice = scene.choices.find(choice => choice.linkToScene === sceneId);
            if (_choice) {
                choice = _choice;
            }
        });

        if (choice) {
            return choice.title
        }
        return sceneId;
    };

    render() {
        const {classes, scenes, shoot, dispatch, ...other} = this.props;

        return (
            <Dialog classes={{paper: classes.dialogPaper, root: classes.root}} onClose={this.handleClose}
                    aria-labelledby="scene-selector" {...other}>
                <DialogTitle id="scene-selector">Select Scene</DialogTitle>
                <div className={classes.dialogContent}>
                    <List>
                        {Object.keys(scenes).map((key, i) => (
                            <ListItem button
                                      onClick={() => this.handleListItemClick(scenes[key])}
                                      key={i}>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <img width="100%"
                                             src={Helpers.getImagePath(
                                                 scenes[key].fragments && scenes[key].fragments.length ? scenes[key].fragments[0].thumbnail : '',
                                                 shoot,
                                                 scenes[key].fragments && scenes[key].fragments.length ? scenes[key].fragments[0].fragmentId : ''
                                             )}/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={'Scene ' + this.getSceneTitle(scenes[key]._id)}/>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Dialog>
        );
    }
}


function mapStateToProps({game, scenes}) {
    return {
        shoot: game.shootId,
        scenes: scenes,
    };
}

SceneSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

export default SceneSelector = connect(mapStateToProps)(withStyles(styles)(SceneSelector));