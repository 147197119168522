import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import shortId from 'shortid';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  FormControl,
  Icon,
  IconButton,
  Input,
  MenuItem,
  Select,
  Typography,
  Switch,
  InputAdornment,
  Avatar,
  ListItemIcon,
  ListItem
} from '@material-ui/core';
import FragmentsEditor from 'components/FragmentsEditor';
import ChoicesEditor from 'components/ChoicesEditor';
import Settings from 'config/settings';
import { handleSaveScene } from '../redux/actions/shared';
import {red} from "@material-ui/core/colors";
import Helpers from "../utils/helpers";

import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  sceneContainer: {
    padding: theme.spacing.unit * 2,
    height: '100%',
    overflowY: 'auto',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    background: '#fff',
    ...theme.mixins.toolbar,
    '& h5': {
      marginLeft: '10px',
      fontWeight: '500',
    },
  },
  title: {
    fontSize: '18px',
    marginTop: '30px',
  },
  highlightedTitle: {
    fontSize: '18px',
  },
  input: {
    width: '100%',
    marginTop: '10px',
  },
  inputPrice: {
    width: '20%',
    paddingRight: '5px',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    marginTop: '10px',
  },
  choices: {
    marginTop: '30px',
  },
  formControl: {
    width: '100%',
    marginTop: '10px',
  },
  button: {
    margin: theme.spacing.unit / 2,
  },
  usavedChangesAlert: {
    color: red[400],
  },
  menuItem: {
    padding: '10px'
  }
});

class SceneEditor extends React.Component {
  state = {
    id: '',
    title: '',
    isHighlighted: false,
    isPublic: false,
    skipToSex: 'none',
    fragments: [],
    choices: [],
    game: '',
    price: 0,
    unsavedChanges: false,
  };

  componentWillReceiveProps(props, next) {
    if (props.scene) {
      this.setState({
        id: props.scene._id,
        title: props.scene.title || '',
        isHighlighted: props.scene.isHighlighted || false,
        isPublic: props.scene.isPublic || false,
        skipToSex: props.scene.skipToSex || '',
        fragments: props.scene.fragments,
        choices: props.scene.choices,
        game: props.gameId,
        price: props.scene.price || 0,
        unsavedChanges: false,
        canDeleteChoice: true,
      });
    }
  }

  handleSkipToSexChange = (event) => {
    this.setState({ skipToSex: event.target.value });
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value, unsavedChanges: true });
  };

  handleIsHighlightedChange = (event) => {
    this.setState({
      isHighlighted: event.target.checked,
      unsavedChanges: true,
    });
  };

  handleIsPublicChange = (event) => {
    this.setState({
      isPublic: event.target.checked,
      unsavedChanges: true,
    });
  };

  handlePriceChange = (event) => {
    this.setState({ price: event.target.value, unsavedChanges: true });
  };

  handleAddFragment = (fragmentId) => {
    if (fragmentId) {
      let { fragments } = this.state;
      const id = shortId.generate();
      const fragment = {
        id: id,
        fragmentId: fragmentId,
        title: '',
        poster: Settings.poster,
        thumbnail: Settings.thumbnail,
        resource: Settings.resource,
        actions: [],
      };
      fragments.push(fragment);
      this.setState({ fragments: fragments, unsavedChanges: true });
    }
  };

  handleUpdateFragment = (fragment) => {
    let { fragments } = this.state;
    const id = fragments.findIndex((_fragment) => _fragment.id === fragment.id);
    fragments[id] = fragment;
    this.setState({ fragments: fragments, unsavedChanges: true });
  };

  handleRemoveFragment = (fragment) => {
    let { fragments } = this.state;
    const index = fragments.findIndex(
      (_fragment) => _fragment.id === fragment.id
    );
    if (index >= 0) {
      fragments.splice(index, 1);
      this.setState({ fragments: fragments, unsavedChanges: true });
    }
  };

  handleAddAction = (fragmentId, actionFragmentId) => {
    let { fragments } = this.state;
    let fragment = fragments.find((_fragment) => _fragment.id === fragmentId);

    const id = shortId.generate();
    const action = {
      id: id,
      fragmentId: actionFragmentId,
      title: '',
      poster: Settings.poster,
      thumbnail: Settings.thumbnail,
      resource: Settings.resource,
    };

    if (fragment.actions) {
      fragment.actions.push(action);
    } else {
      fragment.actions = [action];
    }

    this.setState({ fragments: fragments, unsavedChanges: true });
  };

  handleUpdateAction(fId, action) {
    console.log(fId, action);
    let { fragments } = this.state;
    let fragmentIndex = fragments.findIndex((_f) => _f.id === fId);
    let actionIndex = fragments[fragmentIndex].actions.findIndex(
      (_action) => _action.id === action.id
    );
    fragments[fragmentIndex].actions[actionIndex] = action;

    this.setState({ fragments: fragments });
  }

  handleAddChoice = (fragmentId, sceneId) => {
    const isNew = !sceneId;

    let { choices } = this.state;
    const _id = shortId.generate();

    const choice = {
      id: _id,
      title: '',
      linkToScene: '',
      poster: Settings.thumbnail.replace('[fragmentId]', fragmentId),
      defaultFragment: {
        id: shortId.generate(),
        fragmentId: fragmentId,
        title: '',
        poster: Settings.poster,
        thumbnail: Settings.thumbnail,
        resource: Settings.resource,
        actions: [],
      },
    };

    if (isNew) {
      choice.poster = Settings.thumbnail.replace('[fragmentId]', fragmentId);
    } else {
      choice.linkToScene = sceneId;
      choice.poster = Settings.thumbnail.replace('[fragmentId]', fragmentId);
    }

    choices.push(choice);
    this.setState({ choices: choices, unsavedChanges: true });
  };

  handleUpdateChoice = (choice) => {
    let { choices } = this.state;
    const index = choices.findIndex((_choice) => _choice.id === choice.id);
    choices[index] = choice;
    this.setState({ choices: choices, unsavedChanges: true });
  };

  handleRemoveChoice = (choice) => {
    let { choices } = this.state;
    const index = choices.findIndex((_choice) => _choice.id === choice.id);

    // Check if the next scene has children
    if (index >= 0) {
      const choiceToDelete = choices[index];
      const nextScene = this.props.scenes[choiceToDelete['linkToScene']]
      if (nextScene['choices'].length > 0) {
        this.setState({canDeleteChoice: false});
      } else {
        choices.splice(index, 1);
        this.setState({choices: choices, unsavedChanges: true});
      }
    }
  };

  handleSave = () => {
    this.props.dispatch(handleSaveScene(this.state));
    this.setState({ unsavedChanges: false });
    this.setState({ canDeleteChoice: true });
  };

  renderIconForAsset = (asset) => (
      asset ? <CheckIcon style={{color: "green"}}/> : <CloseIcon color="error"/>
  )

  render() {
    const { classes, scenes, shootId, site } = this.props;
    const { unsavedChanges } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.drawerHeader}>
          <Typography component="h5">
            {this.state.title || 'Scene ?'}
          </Typography>
          <IconButton onClick={this.props.handleDrawerClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <Divider />

        <div className={classes.sceneContainer}>
          <div className={classes.titleContainer}>
            <Typography
                variant="title"
                component="h3"
                className={classes.highlightedTitle}
            >
              Public scene? (Tour visible)
            </Typography>
            <Switch
                style={{color: 'red'}}
                checked={this.state.isPublic}
                onChange={this.handleIsPublicChange}
            />
          </div>
          <div className={classes.titleContainer}>
            <Typography
              variant="title"
              component="h3"
              className={classes.highlightedTitle}
            >
              Highlight?
            </Typography>
            <Switch
              checked={this.state.isHighlighted}
              onChange={this.handleIsHighlightedChange}
            />
          </div>
          <div className={classes.titleContainer}>
            <Typography variant="title" component="h2" className={classes.tile}>
              Price:
            </Typography>
            <Input
              className={classes.inputPrice}
              value={this.state.price}
              onChange={this.handlePriceChange}
              inputProps={{
                'aria-label': 'Price',
                style: { textAlign: 'right' },
              }}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </div>
          <FragmentsEditor
            shootId={shootId}
            site={site}
            fragments={this.state.fragments}
            addFragment={this.handleAddFragment}
            updateFragment={this.handleUpdateFragment}
            removeFragment={this.handleRemoveFragment}
            addAction={this.handleAddAction}
            updateAction={this.handleUpdateAction.bind(this)}
          />
          <Typography variant="title" component="h3" className={classes.title}>
            Question
          </Typography>
          <Input
            className={classes.input}
            value={this.state.title}
            onChange={this.handleTitleChange}
            placeholder="Question?"
            inputProps={{
              'aria-label': 'Question',
            }}
          />
          <ChoicesEditor canDelete={this.state.canDeleteChoice}
                         shoot={shootId}
                         site={site}
                         choices={this.state.choices}
                         addChoice={this.handleAddChoice}
                         updateChoice={this.handleUpdateChoice}
                         removeChoice={this.handleRemoveChoice}
          />
          <Typography variant="title" component="h3" className={classes.title}>
            Link To Sex Scene
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              value={this.state.skipToSex}
              onChange={this.handleSkipToSexChange}
            >
              <MenuItem value="none">None</MenuItem>
              {this.props.scenes &&
                Object.keys(scenes).length > 0 &&
                Object.keys(scenes).map((scene, i) => {

                  const fragment = scenes[scene] && scenes[scene].fragments && scenes[scene].fragments[0] || null;
                  if (fragment == null) return;
                  return (
                        <MenuItem value={scene} key={i}>
                          <ListItem>
                            <ListItemIcon>
                              <Avatar className={classes.avatar}>
                                <img width="100%" src={Helpers.getImagePath(Settings.thumbnail, shootId, fragment.fragmentId)}/>
                              </Avatar>
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                              {fragment.title || (scenes[scene].title || `Scene ${scenes[scene]._id}`)}
                            </Typography>
                          </ListItem>
                        </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
          {this.props.showAssetStatuses && this.state.fragments.length > 0 && Object.keys(this.props.gameHealth).length > 0 && <>
            <div className={classes.titleContainer}>
              <Typography variant="title" component="h3" className={classes.title}>
                Asset Statuses
              </Typography>
            </div>
            <Table style={{width: '100%'}} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell/>
                  <TableCell align="center">Poster</TableCell>
                  <TableCell align="center">Resource</TableCell>
                  <TableCell align="center">Thumbnail</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(this.state.fragments).map(key => {
                  const fragment = this.state.fragments[key];
                  const assetStatus = this.props.gameHealth.scenesAssets[fragment.id];

                  if (!assetStatus) {
                    return (
                        <TableRow
                            key={fragment.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>

                    )
                  }

                  return (
                      <TableRow
                          key={fragment.id}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                      >
                        <TableCell component="th" scope="row">
                          Fragment {fragment.fragmentId}
                        </TableCell>
                        <TableCell align="center">
                          {this.renderIconForAsset(assetStatus.poster)}
                        </TableCell>
                        <TableCell align="center">
                          {this.renderIconForAsset(assetStatus.resource)}
                        </TableCell>
                        <TableCell align="center">
                          {this.renderIconForAsset(assetStatus.thumbnail)}
                        </TableCell>
                  </TableRow>
                  )})}
              </TableBody>
            </Table>
          </>}
        </div>
        <Divider />
        <div className={classes.drawerHeader}>
          {unsavedChanges ? (
            <Typography component="h5" className={classes.usavedChangesAlert}>
              There are unsaved changes!
            </Typography>
          ) : (
            <div></div>
          )}
          <div>

            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={this.handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ game, scenes, gameHealth }, ownProps) {
  return {
    scene: scenes ? scenes[ownProps.selectedScene] : null,
    scenes: scenes,
    shootId: game.shootId,
    gameId: game._id,
    gameHealth: gameHealth,
  };
}

SceneEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  scene: PropTypes.object,
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(SceneEditor)
);
