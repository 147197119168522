import {RECEIVE_GAME_HEALTH} from 'redux/actions/games';

export default function (state = {}, action) {
    switch (action.type) {
        case RECEIVE_GAME_HEALTH:
            return {
                ...state,
                ...action.gameHealth
            };
        default:
            return state;
    }
}