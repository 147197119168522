import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GameDrawer from './GameDrawer';

import Papa from 'papaparse';

import {
    AppBar,
    Dialog,
    Divider,
    Grid,
    Icon,
    IconButton,
    Slide,
    Switch,
    Toolbar,
    Typography,
    Paper
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles/index';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import SceneNode from '../components/SceneNode';
import {connect} from 'react-redux';
import {handleGetGame, handleGetGameHealth, handleGetScenes} from '../redux/actions/shared';
import ZoomController from 'components/ZoomController';
import GameVideoPlayer from '../components/GameVideoPlayer';

import CloseIcon from '@material-ui/icons/Close';

import LoadingBar from 'react-redux-loading';

import EditGame from '../components/EditGame';
import CircularProgress from "@material-ui/core/CircularProgress";
const styles = theme => ({
    root: {
        height: '100%',
        maxWidth: '100%',
        '& .reactOrgChart': {
            display: 'inline-block'
        }
    },
    grid: {
        height: 'calc(100% - 65px)'
    },
    gameHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 12px',
        backgroundColor: '#fff',
        ...theme.mixins.toolbar
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor: '#fff',
        '& button': {
            marginRight: '10px'
        }
    },
    gameTitle: {
        fontWeight: 500
    },
    appBar: {
        position: 'relative'
    },
    flex: {
        flex: 1
    }
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Game extends Component {
    state = {
        drawerOpen: false,
        editGameOpen: false,
        selectedScene: 's1',
        showPlayer: false,
        loadingAssetStatuses: false,
        showAssetStatuses: false,
    };

    handleClickOpenEditGame = () => {
        this.setState({editGameOpen: true});
    };

    handleClickDownloadGameAsJSON = () => {
        const {gameId} = this.props.match.params;
        const {game, scenes} = this.props;
            const gameDataset = {
                game: game,
                scenes: scenes
            }
            // create file in browser
            const fileName = "game_" + gameId + "_export";
            const json = JSON.stringify(gameDataset, null, 2);
            const blob = new Blob([json], { type: "application/json" });
            const href = URL.createObjectURL(blob);


            // create "a" HTLM element with href to file
            const link = document.createElement("a");
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
    }

    handleClickDownloadGame = () => {
        const { gameId } = this.props.match.params;
        const { scenes } = this.props;
        // Convert the game data to CSV format
        const scenesCsv = this.flatten(scenes);
        const csv = Papa.unparse(scenesCsv);
        // Create file in browser
        const fileName = "game_" + gameId + "_export";
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        // Create "a" HTML element with href to file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();

        // Clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }

    flatten = (obj) => {
        const scenes = Object.values(obj);
        let rows = [];

        scenes.forEach((scene, index) => {
            const sceneID = scene._id;

            scene.fragments.forEach((fragment, index) => {
                const trackOrder = String(index + 1);
                const fragmentTitle = fragment.title;
                const videoFragmentID = fragment.fragmentId;

                // Iterate over each choice
                if (scene.choices.length > 0) {
                    scene.choices.forEach(choice => {
                        const optionsQuestion = scene.title;
                        const optionTitle = choice.title;
                        const optionSceneID = choice.linkToScene;
                        const optionVideoFragmentID = choice.defaultFragment.fragmentId;

                        if (fragment.actions && fragment.actions.length > 0) {
                            // Iterate over each action in fragment
                            fragment.actions.forEach(action => {
                                let fragmentRow = this.createFragmentRow(
                                  sceneID, trackOrder, fragmentTitle, videoFragmentID,
                                  action.title, action.fragmentId,
                                  optionsQuestion, optionTitle, optionSceneID, optionVideoFragmentID
                                );
                                rows.push(fragmentRow);
                            });
                        } else {
                            let fragmentRow = this.createFragmentRow(
                              sceneID, trackOrder, fragmentTitle, videoFragmentID,
                              "", "", optionsQuestion, optionTitle, optionSceneID, optionVideoFragmentID
                            );
                            rows.push(fragmentRow);
                        }
                    });

                } else {
                    let fragmentRow = this.createFragmentRow(
                      sceneID, trackOrder, fragmentTitle, videoFragmentID,
                      "", "", "", "", "", ""
                    );
                    rows.push(fragmentRow);
                }
            });
        });

        return rows;
    }
    createFragmentRow = (sceneId, trackOrder, title, videoFragmentID,
                               angleTitle, angleVideoFragmentID,
                               optionQuestion, optionTitle, optionSceneID, optionVideoFragmentID) => {
        return {
            sceneId,
            trackOrder,
            title,
            videoFragmentID,
            angleTitle,
            angleVideoFragmentID,
            optionQuestion,
            optionTitle,
            optionSceneID,
            optionVideoFragmentID
        }

    }


    handleCloseEditGame = () => {
        this.setState({editGameOpen: false});
    };

    handleSaveGame = (game) => {
        this.setState({editGameOpen: false});
        console.log(game);
    };

    componentWillReceiveProps(nextProps, nextContext) {
       if (this.state.loadingAssetStatuses && nextProps.gameHealth) {
           this.setState({
               loadingAssetStatuses: false
           });
       }
    }

    componentDidMount() {
        const {gameId} = this.props.match.params;
        this.props.dispatch(handleGetGame(gameId));
        this.props.dispatch(handleGetScenes(gameId));
        document.config = {
            gameID: gameId,
            apiUrl: '/api',
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJuYW1lIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW4ifSwiaWF0IjoxNTkwMDkyMzIwfQ.FFUA42vshXKWhAIdUbDjteC-RL3OcRVYlkgZeFV9qCM',
        };
    }

    updateSelectedScene = id => {
        this.setState({selectedScene: id, drawerOpen: true});
    };

    handleDrawerClose = () => {
        this.setState({drawerOpen: false});
    };

    handleDrawerOpen = () => {
        this.setState({drawerOpen: true});
    };

    handleShowPlayer = (event) => {
        this.setState({
            showPlayer: event.target.checked
        });
    }

    handleShowAssetStatusesChange = () => {
        if (!this.state.showAssetStatuses) {
            return;
        }

        this.setState({
            loadingAssetStatuses: true
        });

        this.props.dispatch(handleGetGameHealth(this.props.match.params.gameId));
    }

    handleShowAssetStatuses = (event) => {
        this.setState({
            showAssetStatuses: event.target.checked
        }, this.handleShowAssetStatusesChange);
    }

    handleBack = () => {
        window.history.back();
    };

    getAllChoices = (scenes) => {
        const choices = [];
        if (scenes && scenes.length > 0) {
            scenes.forEach(scene => {
                if (scene.choices && scene.choices.length > 0) {
                    scene.choices.forEach(_choice => {
                        choices.push(_choice);
                    })
                }
            })
        }

        return choices;
    };

    render() {
        const {gameId} = this.props.match.params;
        const {classes, game, scenes} = this.props;
        // console.log(scenes)

        const sceneNode = ({node}) => {
            return (
                <SceneNode
                    node={node}
                    shoot={game.shootId}
                    site={game.site}
                    choices={this.getAllChoices(scenes)}
                    selected={this.state.selectedScene}
                    loadingAssetStatuses={this.state.loadingAssetStatuses}
                    showAssetStatuses={this.state.showAssetStatuses}
                    onSelectScene={this.updateSelectedScene}
                />
            );
        };

        return (
            <div className={classes.root}>
                <div>
                    <LoadingBar/>
                    <Dialog
                        fullScreen
                        open={this.state.editGameOpen}
                        onClose={this.handleCloseEditGame}
                        TransitionComponent={Transition}
                    >
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color="inherit" onClick={this.handleCloseEditGame} aria-label="Close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="title" color="inherit" className={classes.flex}>
                                    Editing: "{game.title ? game.title : gameId}"
                                </Typography>

                            </Toolbar>
                        </AppBar>
                        <EditGame id={gameId} closeEditGame={this.handleCloseEditGame}/>
                    </Dialog>
                </div>
                <div className={classes.root}>
                    <Grid className={classes.grid}>
                        <div className={classes.gameHeader}>
                            <div className={classes.title}>
                                <IconButton onClick={this.handleBack}>
                                    <Icon>arrow_back</Icon>
                                </IconButton>
                                <Typography component="h4" variant="subheading">
                                    Game <span className={classes.gameTitle}>{game.title ? game.title : gameId}</span>
                                </Typography>
                            </div>
                            <div className={classes.title}>
                                <Typography
                                    component="h4" variant="subheading"
                                >Show Asset Statuses?
                                </Typography>
                                {this.state.loadingAssetStatuses && <CircularProgress
                                    size={24}
                                    style={{margin: '10px'}}
                                        />}
                                {!this.state.loadingAssetStatuses && <Switch
                                    checked={this.state.showAssetStatuses}
                                    onChange={this.handleShowAssetStatuses}
                                />}
                                <Typography
                                    component="h4" variant="subheading"
                                >Show Player?
                                </Typography>
                                <Switch
                                    checked={this.state.showPlayer}
                                    onChange={this.handleShowPlayer}
                                />
                                <IconButton onClick={this.handleClickDownloadGame}>
                                    <Icon>download</Icon>
                                </IconButton>
                                <IconButton onClick={this.handleClickDownloadGameAsJSON}>
                                    <Icon>code</Icon>
                                </IconButton>
                                <IconButton onClick={this.handleClickOpenEditGame}>
                                    <Icon>edit</Icon>
                                </IconButton>
                            </div>
                        </div>
                        <Divider/>
                        <GameDrawer
                            scenes={scenes}
                            site={game.site}
                            selectedScene={this.state.selectedScene}
                            drawerOpen={this.state.drawerOpen}
                            showAssetStatuses={this.state.showAssetStatuses}
                            handleDrawerOpen={this.handleDrawerOpen}
                            handleDrawerClose={this.handleDrawerClose}
                        >
                            {game.tree && <OrgChart tree={game.tree} NodeComponent={sceneNode}/>}

                            <GameVideoPlayer isVisible={this.state.showPlayer} />

                            <ZoomController/>
                        </GameDrawer>
                    </Grid>
                </div>
            </div>
        );
    }
}

function mapStateToProps({game, scenes, gameHealth}) {
    return {
        game: game,
        scenes: scenes,
        gameHealth: gameHealth
    };
}

Game.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(Game));
