import {getGame, getInitialData, getScenes, saveScene, getGames, getGameHealth} from 'utils/api';
import { hideLoading, showLoading } from 'react-redux-loading';
import {receiveGame, receiveGameHealth, receiveGames} from './games';
import { receiveScenes } from './scenes';

export default function handleInitialData() {
  return dispatch => {
    dispatch(showLoading());
    return getInitialData().then(games => {
      dispatch(receiveGames(games));
      dispatch(hideLoading());
    });
  };
}

export function handleGetGameHealth(id) {
  return dispatch => {
    return getGameHealth(id).then(gameHealth => {
      dispatch(receiveGameHealth(gameHealth));
    })
  }
}

export function handleGetGame(id) {
  return dispatch => {
    return getGame(id).then(game => {
      dispatch(receiveGame(game));
    });
  };
}

export function handleGetScenes(id) {
  return dispatch => {
    return getScenes(id).then(scenes => {
      dispatch(receiveScenes(scenes));
    });
  };
}

export function handleSaveScene(scene) {
  const id = scene.game;
  return (dispatch, getState) => {
    dispatch(showLoading());
    return saveScene(scene)
      .then(() => getScenes(id))
      .then(scenes => {
        dispatch(receiveScenes(scenes));
      })
      .then(() => getGames()
      .then(games => {
        dispatch(receiveGames(games));
        dispatch(hideLoading());
      }))
      .then(() => getGame(id))
      .then(game => {
        dispatch(receiveGame(game));
      });
  };
}

