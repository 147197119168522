import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import {connect} from "react-redux";
import Helpers from "../utils/helpers";
import Settings from 'config/settings'

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',

    },
    dialogPaper: {
        maxHeight: '50%'
    },
    dialogContent: {
        height: '100%',
        overflow: 'auto'
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
};

class FragmentSelector extends React.Component {
    handleClose = () => {
        this.props.onClose('');
    };

    handleListItemClick = value => {
        this.props.onClose(value);
    };

    padNumber = (num) => {
        return num < 10 ? '0' + num : '' + num;
    };

    render() {
        const {classes, fragments, shoot, dispatch, ...other} = this.props;

        return (
            <Dialog classes={{paper: classes.dialogPaper, root: classes.root}} onClose={this.handleClose}
                    aria-labelledby="fragment-selector" {...other}>
                <DialogTitle id="fragment-selector">Select Fragment</DialogTitle>
                <div className={classes.dialogContent}>
                    <List>
                        {Array.from(new Array(fragments), (val, index) => index + 1)
                            .map(fragment => (
                                <ListItem button onClick={() => this.handleListItemClick(this.padNumber(fragment))}
                                          key={fragment}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <img width="100%"
                                                 src={Helpers.getImagePath(Settings.thumbnail, shoot, this.padNumber(fragment))}/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={'Fragment ' + this.padNumber(fragment)}/>
                                </ListItem>
                            ))}
                    </List>
                </div>
            </Dialog>
        );
    }
}


function mapStateToProps({game}) {
    return {
        shoot: game.shootId,
        fragments: game.fragments,
    };
}

FragmentSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

export default FragmentSelector = connect(mapStateToProps)(withStyles(styles)(FragmentSelector));