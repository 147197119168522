import { saveGameApi, updateGameApi, deleteGameApi } from 'utils/api'
import { showLoading, hideLoading } from 'react-redux-loading'

export const RECEIVE_GAMES = 'RECEIVE_GAMES';
export const RECEIVE_GAME = 'RECEIVE_GAME';
export const RECEIVE_GAME_HEALTH = 'RECEIVE_GAME_HEALTH';
export const REMOVE_SELECTED_GAME = 'REMOVE_SELECTED_GAME';
export const ADD_GAME = 'ADD_GAME';
export const UPDATE_GAME = 'UPDATE_GAME';
export const REMOVE_GAME = 'REMOVE_GAME';

export function receiveGames(games) {
    return {
        type: RECEIVE_GAMES,
        games
    };
}

export function receiveGameHealth(gameHealth) {
    return {
        type: RECEIVE_GAME_HEALTH,
        gameHealth
    }
}

export function receiveGame(game) {
    return {
        type: RECEIVE_GAME,
        game
    };
}

export function addGame (game) {
  return {
    type: ADD_GAME,
    game
  }
}

export function updateGame (game) {
    return {
        type: UPDATE_GAME,
        game
    }
}

export function removeGame (game) {
    return {
        type: REMOVE_GAME,
        game
    }
}

export function removeSelectedGame() {
    return {
        type: REMOVE_SELECTED_GAME
    }
}

export function handleUpdateGame (game) {
    return (dispatch, getState) => {
        dispatch(showLoading())
        return updateGameApi(game)
            .then((game) => {
                dispatch(updateGame(game))
                dispatch(receiveGame(game))
            })
            .then(() => dispatch(hideLoading()))
    }
}

export function handleDeleteGame (game) {
    return (dispatch, getState) => {
        dispatch(showLoading())
        return deleteGameApi(game)
            .then((game) => dispatch(removeGame(game)))
            .then(() => dispatch(hideLoading()))
    }
}

export function handleAddGame(game) {
  return (dispatch, getState) => {
    dispatch(showLoading())
    return saveGameApi(game)
      .then((game) => dispatch(addGame(game)))
      .then(() => dispatch(hideLoading()))
  }
}