import Auth from "./auth";
import FetchInterceptor from "fetch-interceptor";

FetchInterceptor.register({
    onBeforeRequest(request) {
        if (Auth.isAuthenticated()) {
            if (!request.headers.get('Authorization')) {
                request.headers.set('Authorization', 'Bearer ' + Auth.getToken());
            }
        }

    },
    onRequestSuccess(response, request) {
        if (response.status == 401) {
            Auth.removeToken();
            window.location = '/login'
        }
    },
    onRequestFailure(response, request) {
        if (response.status == 401) {
            Auth.removeToken();
            window.location = '/login'
        }
    }
});

export const login = (username, password) => {
    return fetch('/api/user/login', {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({username: username, password: password})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText || response.status);
            error.response = response;
            return Promise.reject(error)
        }
    });
};

export function getInitialData() {
    return getGames().then(games => {
        return games;
    });
}

export function getGames() {
    return fetch('/api/games').then(response => {
        return response.json();
    }).then(results => {
        return results.reduce((obj, item) => {
            obj[item['_id']] = item;
            return obj;
        }, {});
    });
}

export function getGameHealth(id) {
    return fetch(`/api/games/${id}/health`)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        });
}

export function getGame(id) {

    return fetch(`/api/games/${id}`)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        });
}

export function getScenes(gameId) {
    return fetch(`/api/games/${gameId}/scenes`)
        .then(response => {
            return response.json();
        })
        .then(results => {
            return results.reduce((obj, item) => {
                obj[item['_id']] = item;
                return obj;
            }, {});
        });
}

export function saveGameApi(game) {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(game)
    };

    return fetch(`/api/games`, options)
        .then(response => {
            return response.json();
        });
}

export function updateGameApi(game) {
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(game)
    };
    return fetch(`/api/games/${game._id}`, options)
        .then(response => {
            return response.json();
        });
}

export function deleteGameApi(game) {
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ status: "deleted" })
    };
    return fetch(`/api/games/${game._id}`, options)
        .then(response => {
            return response.json();
        });
}

export function saveScene(scene) {
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(scene)
    };
    return fetch(`/api/scenes/${scene.id}`, options)
        .then(response => {
            return response.json();
        });
}

