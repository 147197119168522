import React, { Component } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChipInput from 'material-ui-chip-input';
import Button from '@material-ui/core/Button';
import { handleAddGame } from '../redux/actions/games';
import Grid from '@material-ui/core/Grid';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  grid: {
    '& div': {
      width: '210px'
    }
  },
  chipInput: {
    marginTop: '22px',
    paddingTop: '10px'
  }
});

class NewGame extends Component {
  state = {
    title: '',
    description: '',
    poster: '',
    trailer: '',
    guide: '',
    shootId: '',
    site: 'sexadventure',
    video: '',
    resolutions: ['360p', '480p', '720p', '1080p', '2160p'],
    fragments: 0,
    status: 'disable'
  };

  handleAddResolution = resolution => {
    this.setState({
      resolutions: this.state.resolutions.concat([resolution])
    });
  };

  handleDeleteResolution = resolution => {
    const resolutions = this.state.resolutions.filter(c => c !== resolution);

    this.setState({ resolutions });
  };

  getTemplate = (name, shootId, site) => {
    if (name === 'trailer') {
      return `https://trailers1.bangbros.com/${site}/${shootId}/trailerx/trailer_1500.mp4`;
    }
    if (name === 'video') {
      return `//cdn77-lg-members.bangbros.com/shoots/${site}/${shootId}/streaming/${shootId}-1080p.mp4`
    }
    if (name === 'poster') {
      return `//sm-members.bangbros.com/shoots/${site}/${shootId}/poster/${shootId}_01_2160.jpg`;
    }
  }

  handleChange = name => event => {

    this.setState({
      [name]: event.target.value
    });

    if (name === 'fragments' && (event.target.value < 0 || event.target.value === '')) {
      this.setState({ fragments: 0 });
    }

    if (name === 'shootId') {
      this.setState({
          ['trailer']: this.getTemplate('trailer', event.target.value, this.state.site),
          ['video']: this.getTemplate('video', event.target.value, this.state.site),
          ['poster']: this.getTemplate('poster', event.target.value, this.state.site)
        })
    }

    if (name === 'site') {
      this.setState({
          ['trailer']: this.getTemplate('trailer', this.state.shootId, event.target.value),
          ['video']: this.getTemplate('video', this.state.shootId, event.target.value),
          ['poster']: this.getTemplate('poster', this.state.shootId, event.target.value)
        })
    }
  }

  validate = () => {
    return this.state.title.length > 10 &&
        this.state.description.length > 20 &&
        this.state.shootId.length > 5 &&
        this.state.fragments > 0 &&
        this.state.resolutions.length > 0;
  };

  handleSubmit = e => {
    e.preventDefault();
    e.target.disabled = true;

    const { dispatch, handleClose } = this.props;
    const { title, description, poster, trailer, guide, resolutions, fragments, shootId, status, site, video } = this.state;
    if (this.state.title && this.state.description && this.state.site) {
      dispatch(handleAddGame({ title, description, poster, trailer, guide, resolutions, fragments, shootId, status, site, video }));
      e.target.disabled = false;
      handleClose();
    }
  };

  render() {
    const { classes } = this.props;
    const formValid = this.validate();

    return (
      <div style={{maxWidth: '700px'}}>
        <DialogTitle id="form-dialog-title">Add new Game</DialogTitle>
        <DialogContent>
          <DialogContentText>To create a new game. Fillout the the form and click 'Add game'.</DialogContentText>
          <form noValidate autoComplete="off">
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.grid}>
              <TextField
                margin="dense"
                id="shootId"
                label="Shoot Id"
                type="text"
                value={this.state.shootId}
                required
                onChange={this.handleChange('shootId')}
              />
              <TextField
                  id="site"
                  label="Site"
                  type="text"
                  value={this.state.site}
                  onChange={this.handleChange('site')}
                  placeholder="sexadventure"
                  required
                  margin="dense"
                />
              <TextField
                id="fragments"
                label="How many video fragments?"
                value={this.state.fragments}
                onChange={this.handleChange('fragments')}
                type="number"
                min="0"
                margin="dense"
              />
            </Grid>
            <TextField
              margin="dense"
              id="title"
              label="Title (Min 10 chars)"
              type="text"
              fullWidth
              value={this.state.title}
              required
              onChange={this.handleChange('title')}
            />
            <TextField
              margin="dense"
              id="description"
              label="Description  (Min 20 chars)"
              type="text"
              fullWidth
              multiline
              rowsMax="4"
              required
              value={this.state.description}
              onChange={this.handleChange('description')}
            />
            <TextField
              margin="dense"
              id="guide"
              label="Guide image url"
              placeholder="https://yourImagePath/imageName.png"
              type="text"
              fullWidth
              value={this.state.guide}
              onChange={this.handleChange('guide')}
            />
            <TextField
              margin="dense"
              id="poster"
              label="Poster image url"
              placeholder="https://yourPosterImagePath.com/yourPosterImageName.png"
              type="text"
              fullWidth
              value={this.state.poster}
              onChange={this.handleChange('poster')}
            />
            <TextField
              margin="dense"
              id="trailer"
              label="Trailer video url"
              placeholder="https://yourTrailerVideoPath.com/yourTrailerVideoName.mp4"
              type="text"
              fullWidth
              value={this.state.trailer}
              onChange={this.handleChange('trailer')}
            />
            <TextField
                margin="dense"
                id="video"
                label="Full video url"
                placeholder="https://yourFullVideoPath.com/yourFullVideoName.mp4"
                type="text"
                fullWidth
                value={this.state.video}
                onChange={this.handleChange('video')}
            />
            <ChipInput
              required
              className={classes.chipInput}
              helperText="Add new resolutions here (ie: 1080p, 480p, 360p)"
              label="Resolutions availables:"
              value={this.state.resolutions}
              fullWidth
              allowDuplicates={false}
              onAdd={resolution => this.handleAddResolution(resolution)}
              onDelete={(resolution, index) => this.handleDeleteResolution(resolution, index)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>Cancel</Button>
          <Button onClick={this.handleSubmit} disabled={!formValid} color="primary">
            Add Game
          </Button>
        </DialogActions>
      </div>
    );
  }
}

NewGame.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect()(withStyles(styles)(NewGame));
