import {RECEIVE_SCENES} from 'redux/actions/scenes';

export default function (state = {}, action) {
    switch (action.type) {
        case RECEIVE_SCENES:
            return {
                ...state,
                ...action.scenes
            };
        default:
            return state;
    }
}
