import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';
import { removeSelectedGame, handleDeleteGame } from '../redux/actions/games'
import LoadingBar from 'react-redux-loading';
import NewGame from '../components/NewGame';
import GamePreviewVideoPlayer from "../components/GamePreviewVideoPlayer";
import GameDeleteConfirmDialog from "../components/GameDeleteConfirmDialog";

const styles = theme => ({
  root: {
    width: '99%',
    maxHeight: '85vh',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '1000px'
    }
  },

  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  }
});

class GameList extends Component {
  state = {
    open: false,
    showPlayer: false,
    gameIdToDelete: null,
    confirmDeleteDialogOpen: false,
  };

  componentDidMount() {
   // We cleanup the selected game
   this.props.dispatch(removeSelectedGame());
    document.config = {
      apiUrl: '/api',
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJuYW1lIjoiYWRtaW4iLCJuYW1lIjoiQWRtaW4ifSwiaWF0IjoxNTkwMDkyMzIwfQ.FFUA42vshXKWhAIdUbDjteC-RL3OcRVYlkgZeFV9qCM',
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

    handleDeleteGame = (gameId) => {
        this.setState({ confirmDeleteDialogOpen: true, gameIdToDelete: gameId });
    }

    handleConfirmDeleteGame = () => {
        if (this.state.gameIdToDelete) {
            const { dispatch } = this.props;
            dispatch(handleDeleteGame({ _id: this.state.gameIdToDelete }));
            this.setState({ confirmDeleteDialogOpen: false, gameIdToDelete: null });
        }
    }

  handleClickPreview = (gameId) => {
    document.config.gameID = gameId;
    this.setState({ showPlayer: true });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <LoadingBar />
        <Grid container direction="column" justify="center" alignItems="center">
          {this.props.games.length > 0 && (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Shoot ID</TableCell>
                    <TableCell>Game ID</TableCell>
                    <TableCell>Site</TableCell>
                    <TableCell>Game name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell numeric>
                        {this.props.user.username === "admin" && <Button
                        size="small"
                        variant="outlined"
                        color="default"
                        aria-label="Add"
                        className={classes.button}
                        onClick={this.handleClickOpen}
                      >
                        Add
                        <Icon className={classes.rightIcon}>add</Icon>
                      </Button>}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.props.games.map(game => {
                    return (
                      <TableRow key={game._id}>
                        <TableCell component="th" scope="row">
                          {game.shootId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {game._id}
                        </TableCell>
                        <TableCell>{game.site}</TableCell>
                        <TableCell>{game.title}</TableCell>
                        <TableCell>{game.status}</TableCell>
                        <TableCell numeric>
                          {this.props.user.username === "admin" && (
                              <>
                                  <Link to={`/admin/games/${game._id}`}>
                                      <Icon className={classes.icon} color="action">
                                          edit
                                      </Icon>
                                  </Link>
                                  <Icon className={classes.icon} color="action" style={{cursor: "pointer"}} onClick={this.handleDeleteGame.bind(null, game._id)}>
                                      delete
                                  </Icon>
                              </>
                          )}
                          <Icon className={classes.icon} color="action" style={{cursor: "pointer"}} onClick={this.handleClickPreview.bind(null, game._id)}>
                            play_circle
                          </Icon>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth={false}>
          <NewGame handleClose={this.handleClose} />
        </Dialog>
          <GameDeleteConfirmDialog
              open={this.state.confirmDeleteDialogOpen}
              onClose={() => this.setState({ confirmDeleteDialogOpen: false, gameIdToDelete: null })}
              onDelete={this.handleConfirmDeleteGame}
          />
          <GamePreviewVideoPlayer isVisible={this.state.showPlayer} onClose={() => this.setState({ showPlayer: false })} />
      </div>
    );
  }
}

function mapStateToProps({games, auth}) {
    const { user } = auth;
    return {
        games: Object.values(games),
        user,
    };
}

GameList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(GameList));
