import settings from 'config/settings'

class Helpers {
    static getImagePath(url, shootId, fragmentId = null, site = settings.site) {
        if (!url)
            return;

        const replacementVars = {
            image_server: settings.image_server + '/shoots/' + site,
            ShootID: shootId,
            fragmentId: fragmentId,
        };

        Object.keys(replacementVars).forEach(key => {
            url = url.replace(RegExp('\\[' + key + '\\]', 'gm'), replacementVars[key]);
        });

        return url;
    }

    static getVideoPath(url, shootId, fragmentId, resolution, site = settings.site) {
        const replacementVars = {
            video_server: settings.video_server + '/shoots/' + site,
            ShootID: shootId,
            fragmentId: fragmentId,
            resolution: resolution,
        };

        Object.keys(replacementVars).forEach(key => {
            url = url.replace(RegExp('\\[' + key + '\\]', 'gm'), replacementVars[key]);
        });

        return url;
    }
}

export default Helpers