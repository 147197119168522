const Settings = {
    site: "sexadventure",
    image_server: "sm-members.bangbros.com",
    video_server: "cdn77-lg-members.bangbros.com",
    poster: "//[image_server]/[ShootID]/poster/[ShootID]_[fragmentId]_2160.jpg",
    thumbnail: "//[image_server]/[ShootID]/square/[ShootID]_[fragmentId]_400.jpg",
    resource: "//[video_server]/[ShootID]/[resolution]/[ShootID]_[fragmentId]_[resolution].mp4"
};

export default Settings;
