import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Button, Icon, IconButton, Menu, MenuItem, Toolbar, Typography} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import connect from "react-redux/es/connect/connect";
import {logout} from "../redux/actions/auth";

const drawerWidth = 240;

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    toolbarTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    loggedInUserBtn: {
        marginRight: '20px',

    },
    loggedInUserBtnText: {
        marginLeft: '5px'
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    hide: {
        display: 'none'
    }
});

class AppToolbar extends React.Component {

    state = {
        anchorEl: null,
        canDeploy: false,
        message: '',
        ttl: 1000 * 60 * 15 // 15 minutes
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({canDeploy: true, message: ""});
        }, this.state.ttl);
    }

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleLogout = () => {
        this.props.logout();
        this.handleClose();
    };

    handleDeploy = () => {
        const exp = this.getWithExpiry('nextDeployment');
        if (exp == null) { // can deploy
            this.setState({canDeploy: false, message: "Deploying..."});
            const url = 'https://api.vercel.com/v1/integrations/deploy/prj_shtbnmdhTy2uI8qLPchfkGtmg5EH/PRI261AjaB';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                console.log('Deployed');
                this.setWithExpiry('nextDeployment', this.state.ttl);
                this.setState({message: ""});
                setTimeout(() => {
                    this.setState({canDeploy: true, message: ""});
                }, this.state.ttl + 1000);
            });
        } else {
            const msg = `Deployment is not possible yet. Try again in ${(new Date(exp - Date.now()).toISOString().slice(11,19))} minutes`;
            this.setState({canDeploy: false, message: msg});
            console.log(msg);
            const now = new Date()
            setTimeout(() => {
                this.setState({canDeploy: true, message: ""});
            }, exp - now.getTime() + 1000);
        }
    };

     getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.expiry
    }

    setWithExpiry = (key, expiration) => {
        const now = new Date()
        const item = {
            expiry: now.getTime() + expiration,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    render() {
        const {classes, auth} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <AppBar position="absolute" className={classNames(classes.appBar, this.props.open && classes.appBarShift)}>
                <Toolbar disableGutters={!this.props.open} className={classes.toolbar}>
                    <div className={classes.toolbarTitle}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.props.handleDrawerToggle}
                            className={classNames(classes.menuButton, this.props.open && classes.hide)}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Typography variant="title" color="inherit" noWrap
                                    className={classNames(this.props.open && classes.hide)}>
                            Sex Adventure
                        </Typography>

                    </div>
                    <div className={classes.toolbarTitle}>
                        <Typography variant="subheading" color="inherit" noWrap
                                    className={classNames(this.props.open && classes.hide)}>
                            {this.state.message}
                        </Typography>
                    </div>

                    {auth.user && (
                        <div className={classes.loggedInUserBtn}>
                            <Button
                                aria-owns={open ? 'menu-appbar' : null}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <Icon><AccountCircle/></Icon>
                                <span className={classes.loggedInUserBtnText}>{auth.user.name}</span>
                            </Button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={this.handleDeploy} disabled={!this.state.canDeploy} style={{color:"red"}}>Deploy</MenuItem>
                                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        );
    }
}

AppToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(AppToolbar));
