import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Button, FormControl, Grid, Icon, Input} from '@material-ui/core';
import red from '@material-ui/core/colors/red';

import Helpers from 'utils/helpers'

const styles = theme => ({
    root: {},
    resourceContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'center',
        marginBottom: '12px',
        ' & video': {
            width: '100%',
            maxHeight: '136px',
        },
        ' & div': {
            width: '50%',
            padding: '4px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center'
        },
        ' & img': {
            maxHeight: '136px',
            margin: 'auto',
            maxWidth: '100%'
        }
    },
    actionContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'center',
        ' & div': {
            width: '50%',
            padding: '4px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center'
        },
        ' & button': {
            margin: 'auto'
        },
    },
    marginLeft: {
        marginLeft: '2px'
    },
    marginRight: {
        marginRight: '2px'
    },
    editorContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        '& button': {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        }
    },
    btnIcon: {
        width: 'auto',
        height: 'auto',
        fontSize: '16px',
        marginRight: '4px'
    }
});

class ResourcesEditor extends React.Component {
    state = {
        title: ''
    };

    handleTitleChange = event => {
        let fragment = this.props.fragment;
        fragment.title = event.target.value;
        this.props.onTitleUpdate(fragment);
    };

    handleOnDelete() {
        this.props.onDelete(this.props.fragment)
    }

    render() {
        const {classes, shoot, fragment, site} = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <div className={classes.editorContainer}>
                        <FormControl>
                            <Input
                                className={classes.input}
                                value={fragment.title}
                                onChange={this.handleTitleChange.bind(this)}
                                placeholder='Title'
                                inputProps={{
                                    'aria-label': 'Title',
                                }}/>
                        </FormControl>

                        <Button variant="outlined"
                                className={classes.button}
                                onClick={this.handleOnDelete.bind(this)}>
                            <Icon className={classes.btnIcon}>delete</Icon> Delete
                        </Button>
                    </div>
                    <div className={classes.resourceContainer}>
                        <div className={classes.marginRight}>
                            <video
                                controls
                                src={'/api/cdn/sign/?redirect=true&url=' +
                                Helpers.getVideoPath(fragment.resource, shoot, fragment.fragmentId, '480p', site)}
                                poster={Helpers.getImagePath(fragment.poster, shoot, fragment.fragmentId, site)}/>
                        </div>
                        <div className={classes.marginLeft}>
                            <img src={Helpers.getImagePath(fragment.poster, shoot, fragment.fragmentId, site)}/>
                        </div>
                    </div>
                    {/*<div className={classes.actionContainer}>*/}
                    {/*<div className={classes.marginRight}>*/}
                    {/*<Button size="small" color="primary">*/}
                    {/*Change Video*/}
                    {/*</Button>*/}
                    {/*</div>*/}
                    {/*<div className={classes.marginRight}>*/}
                    {/*<Button size="small" color="primary">*/}
                    {/*Change Poster*/}
                    {/*</Button>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                </Grid>
            </div>
        );
    }
}

ResourcesEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    shoot: PropTypes.string,
    fragment: PropTypes.object,
    onTitleUpdate: PropTypes.func,

};

export default withStyles(styles, {withTheme: true})(ResourcesEditor)