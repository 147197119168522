import Icon from "@material-ui/core/Icon";
import {withStyles} from "@material-ui/core/styles";
import React from "react";
import {Button} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1500",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0,0,0,0.4)",
        backdropFilter: "blur(4px)",
    },
    container: {
        border: "5px solid white",
        minWidth: "42vw",
        minHeight: "46vh",
        position: "relative",
        boxSizing: "border-box",
        boxShadow: "0 0 10px #000",
        background: "whitesmoke",
        after: {
            content: "",
            display: "block",
            paddingBottom: "100%"
        }
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
    },
    circle: {
        position: "absolute",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        top: "-20px",
        right: "-20px",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 0 10px #000",
    },
    buttons: {
        bottom: "-5px",
        padding: "10px",
        right: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: {
        cursor: "pointer",
        fontWeight: "bold",
    },
    desktopItem: {
        height: "15vh",
    },
    mobileItem: {
        height: "15vh",
    },
    headerItem: {
        padding: ".5em",
        overflow: "hidden",
    }
});

const FeatureHeaderStatus = ({ isVisible, classes, shootId, site, onClose }) => {
    if (!isVisible) {
        return (
            <></>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.contentWrapper}>
                    <div className={classes.content}>
                        <div className={classes.headerItem}>
                            <h4>Desktop</h4>
                            <div>
                                <h5>Header Image</h5>
                                <img src={`https://trailers3.bangbros.com/${site}/${shootId}/tour/header.jpg`}
                                     alt="Desktop Header Image"
                                     className={classes.desktopItem} />
                                <h5>Header Video</h5>
                                <video
                                    className={classes.desktopItem}
                                    muted
                                    playsInline
                                    autoPlay
                                    loop
                                    src={`https://trailers3.bangbros.com/${site}/${shootId}/tour/header.mp4`}
                                />
                            </div>
                        </div>
                        <div className={classes.headerItem}>
                            <h4>Mobile</h4>
                            <div>
                                <h5>Header Image</h5>
                                <img src={`https://trailers3.bangbros.com/${site}/${shootId}/tour/header_mobile.jpg`}
                                     alt="Mobile Header Image"
                                     className={classes.mobileItem}/>
                                <h5>Header Video</h5>
                                <video
                                    className={classes.mobileItem}
                                    muted
                                    playsInline
                                    autoPlay
                                    loop
                                    src={`https://trailers3.bangbros.com/${site}/${shootId}/tour/header_mobile.mp4`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.buttons}>
                        <Button onClick={() => onClose(true)} color="primary">
                            Looks Good
                        </Button>
                        <Button onClick={() => onClose(false)} color="secondary">
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className={classes.circle}>
                    <Icon className={classes.icon} color="action" onClick={() => onClose(false)}>
                        close
                    </Icon>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(FeatureHeaderStatus)