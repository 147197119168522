import {login} from 'utils/api'
import Auth from '../../utils/auth';


export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_END = 'AUTH_END';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR_ERRORS = 'AUTH_CLEAR_ERRORS';

export const clear = () => dispatch => {
    dispatch({
        type: AUTH_CLEAR_ERRORS
    });
};

export const signIn = (username, password) => async dispatch => {
    dispatch({
        type: AUTH_IN_PROGRESS,
        payload: true
    });
    try {
        const resp = await login(username, password);
        const token = resp.token;
        Auth.setToken(token);
        const user = Auth.getUser();
        dispatch({
            type: AUTH_IN_PROGRESS,
            payload: false
        });
        dispatch({
            type: AUTH_SUCCESS,
            payload: user
        });
        return user;
    } catch (e) {
        let err;
        if (e.response) {
            err = await e.response.json();
        } else {
            err = e;
        }

        dispatch({
            type: AUTH_IN_PROGRESS,
            payload: false
        });
        dispatch({
            type: AUTH_ERROR,
            payload: err
        });
    }
};

export const checkAuth = () => dispatch => {
    const user = Auth.isAuthenticated() ? Auth.getUser() : null;
    dispatch({
        type: AUTH_SUCCESS,
        payload: user
    });
    return user;
};

export const logout = () => dispatch => {
    Auth.removeToken();
    dispatch({
        type: AUTH_END,
        payload: null
    });
    return null;
};

