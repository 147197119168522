import {ADD_GAME, RECEIVE_GAMES, REMOVE_GAME, UPDATE_GAME} from 'redux/actions/games';

export default function (state = {}, action) {
    switch (action.type) {
        case RECEIVE_GAMES:
            return {
                ...state,
                ...action.games
            };
        case ADD_GAME:
            return {
                ...state,
                [action.game._id]: {...action.game}
            };
        case UPDATE_GAME:
            return {
                ...state,
                [action.game._id]: {...action.game}
            }
        case REMOVE_GAME:
            delete state[action.game._id];
            return state;
        default:
            return state;
    }
}
