import React, { Component } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

class GameDeleteConfirmDialog extends Component {
    state = {
        value: '',
        invalidationCode: ''
    };

    randomString = () => {
        return (Math.random() + 1).toString(36).substring(7);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({ invalidationCode: this.randomString(), value: '' });
        }
    }

    render() {
        const { open, onClose, onDelete } = this.props;
        const { value, invalidationCode } = this.state;

        if (!open) {
            return (
                <></>
            )
        }

        return (
            <Dialog open={open} aria-labelledby="confirm-game-delete-dialog" maxWidth={'xs'}>
                <DialogTitle>Delete this game?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography inline component='strong'>
                            Please confirm you would like to delete this game by typing this confirmation code: {invalidationCode}
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Confirmation code"
                            fullWidth
                            value={value}
                            onChange={(e) => this.setState( { value: e.target.value })}
                            onPaste={(e) => e.preventDefault()}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="default" variant='contained'>
                        Cancel
                    </Button>
                    <Button disabled={invalidationCode !== value} onClick={onDelete} color="primary" variant='contained'>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default GameDeleteConfirmDialog;