import jwtDecode from 'jwt-decode';
const tokenKey = 'liveselector-token';

const getToken = () => {
    return localStorage.getItem(tokenKey);
};

const setToken = (token) => {
    localStorage.setItem(tokenKey, token);
};

const removeToken = () => {
    localStorage.removeItem(tokenKey);
};

const decodeToken = (token) => {
    return jwtDecode(token);
};

const getUser = () => {
    let token = getToken();
    return decodeToken(token);
};

const isValidToken = (token) => {
    let decoded = decodeToken(token);
    return (decoded.iat < new Date().getTime())
};

const isAuthenticated = () => {
    const token = getToken();

    if (!token) {
        return false;
    }

    return isValidToken(token);
};

const Auth = {
    getToken: getToken,
    setToken: setToken,
    removeToken: removeToken,
    getUser: getUser,
    isAuthenticated: isAuthenticated
};

export default Auth