import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {Drawer} from '@material-ui/core';
import SceneEditor from 'components/SceneEditor'

const xsDrawerWidth = window.innerWidth;
const smDrawerWidth = window.innerWidth * 0.8;
const mdDrawerWidth = window.innerWidth * 0.4;
const lgDrawerWidth = window.innerWidth * 0.3;
const xlDrawerWidth = window.innerWidth * 0.2;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 'calc(100% - 65px)',
        maxWidth: '100%'
    },
    appFrame: {
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: smDrawerWidth,
        },
        [theme.breakpoints.up('md')]: {
            width: mdDrawerWidth,
        },
        [theme.breakpoints.up('lg')]: {
            width: lgDrawerWidth,
        },
        [theme.breakpoints.up('xl')]: {
            width: xlDrawerWidth,
        },
        backgroundImage: 'linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)',
        height: '100%'
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'auto',
        maxWidth: '100%',
        '& .zoom-controller': {
            position: 'absolute',
            bottom: '20px',
            opacity: 0,
            transition: 'all 0.4s ease',
            width: '200px',
            left: `calc(50% - 100px)`
        },
        '&:hover .zoom-controller': {
            opacity: 1,
        }
    },
    'content-left': {
        marginLeft: -mdDrawerWidth,
    },
    'content-right': {
        [theme.breakpoints.down('sm')]: {
            marginRight: -smDrawerWidth,
        },
        [theme.breakpoints.up('md')]: {
            marginRight: -mdDrawerWidth,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: -lgDrawerWidth,
        },
        [theme.breakpoints.up('xl')]: {
            marginRight: -xlDrawerWidth,
        },
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'contentShift-left': {
        marginLeft: 0,
    },
    'contentShift-right': {
        marginRight: 0,
        '& .zoom-controller': {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            [theme.breakpoints.up('md')]: {
                left: `calc(50% - ${mdDrawerWidth}px)`,
            },
            [theme.breakpoints.up('lg')]: {
                left: `calc(50% - ${lgDrawerWidth}px)`,
            },
            [theme.breakpoints.up('xl')]: {
                left: `calc(50% - ${xlDrawerWidth}px)`,
            },
        },
    },
});

class GameDrawer extends React.Component {
    state = {
        open: false,
        anchor: 'right',
    };

    render() {
        const {classes, showAssetStatuses, site} = this.props;
        const {anchor} = this.state;
        const open = this.props.drawerOpen;

        const drawer = (
            <Drawer variant="persistent" anchor={anchor} open={open}
                    classes={{paper: classes.drawerPaper}}>
                <SceneEditor selectedScene={this.props.selectedScene}
                             scenes={this.props.scenes}
                             site={site}
                             showAssetStatuses={showAssetStatuses}
                             handleDrawerClose={this.props.handleDrawerClose} />
            </Drawer>
        );

        let before = null;
        let after = null;

        if (anchor === 'left') {
            before = drawer;
        } else {
            after = drawer;
        }

        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    {before}
                    <main className={classNames(classes.content, classes[`content-${anchor}`], {
                        [classes.contentShift]: open,
                        [classes[`contentShift-${anchor}`]]: open,
                    })}>{this.props.children}</main>
                    {after}
                </div>
            </div>
        );
    }
}

GameDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(GameDrawer);