import {
    AUTH_CLEAR_ERRORS,
    AUTH_END,
    AUTH_ERROR,
    AUTH_IN_PROGRESS,
    AUTH_SUCCESS
} from "redux/actions/auth";

const initialState = {
    loading: false,
    user: null,
    error: null,
    interceptor: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SUCCESS:
            const user = action.payload;
            return {...state, ...user};
        case AUTH_END:
            return {...state, ...initialState};
        case AUTH_ERROR:
            const error = action.payload;
            return {...state, ...{error}};
        case AUTH_IN_PROGRESS:
            const loading = action.payload;
            return {...state, ...{loading, error: null}};
        case AUTH_CLEAR_ERRORS:
            return {...state, ...{error: null}};
        default:
            return state;
    }
};
