import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Icon,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ResourcesEditor from 'components/ResourcesEditor';
import ActionsEditor from 'components/ActionsEditor';
import FragmentSelector from 'components/FragmentSelector';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    title: {
        fontSize: '18px'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '12px'
    },
    panelDetails: {
        display: 'flex',
        flexDirection: 'column'
    },
    resourceContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'center',
        marginBottom: '12px',
        ' & video': {
            width: '100%',
            maxHeight: '136px',
        },
        ' & div': {
            width: '50%',
            padding: '4px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center'
        },
        ' & img': {
            maxHeight: '136px',
            margin: 'auto'
        }
    },
    actionContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'center',
        ' & div': {
            width: '50%',
            padding: '4px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center'
        },
        ' & button': {
            margin: 'auto'
        },
    },
    marginLeft: {
        marginLeft: '2px'
    },
    marginRight: {
        marginRight: '2px'
    },
    divider: {
        width: '100%',
        marginBottom: '20px'
    },
});

class FragmentsEditor extends React.Component {
    state = {
        expanded: null,
        open: false,
        title: '',
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleOpenFragmentSelector = () => {
        this.setState({open: true});
    };

    handleCloseFragmentSelector = value => {
        if (typeof value !== 'object')
            this.props.addFragment(value);
        this.setState({open: false});
    };

    handleUpdateFragment(fragment) {
        this.props.updateFragment(fragment);
    }

    handleRemoveFragment(fragment) {
        this.props.removeFragment(fragment);
    }

    render() {
        const {classes, shootId, site} = this.props;
        const {expanded} = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.titleContainer}>
                    <Typography
                        variant='title'
                        component='h3'
                        className={classes.title}>Fragments</Typography>
                    <Button variant="outlined" onClick={this.handleOpenFragmentSelector}>
                        Add <Icon>add</Icon>
                    </Button>
                </div>

                <FragmentSelector
                    open={this.state.open}
                    onClose={this.handleCloseFragmentSelector}/>

                <div>
                    {this.props.fragments.map((fragment, index) => {
                        return (
                            <ExpansionPanel
                                key={index}
                                expanded={expanded === fragment.id}
                                onChange={this.handleChange(fragment.id)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}>
                                    <Typography className={classes.heading}>
                                        {fragment.fragmentId ? 'Fragment ' + fragment.fragmentId : 'Fragment ?'}
                                    </Typography>
                                    <Typography
                                        className={classes.secondaryHeading}>
                                        {fragment.title}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    className={classes.panelDetails}>
                                    <ResourcesEditor
                                        shoot={shootId}
                                        site={site}
                                        fragment={fragment}
                                        onTitleUpdate={this.handleUpdateFragment.bind(this)}
                                        onDelete={this.handleRemoveFragment.bind(this)}/>
                                    <Divider className={classes.divider}/>
                                    <ActionsEditor
                                        shootId={shootId}
                                        site={site}
                                        actions={fragment.actions}
                                        addAction={this.props.addAction}
                                        updateAction={this.props.updateAction}
                                        fId={fragment.id}/>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>);
                    })}
                </div>

            </div>
        );
    }
}

FragmentsEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    addAction: PropTypes.func,
    updateFragment: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(FragmentsEditor)